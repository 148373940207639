import { extendTheme } from '@chakra-ui/react';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  BG_OPACITY,
  ASSETS_PRIMARY,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,
    body_lv2: FONT_FAMILY.body_lv2,
    name: FONT_FAMILY.name,
    and: FONT_FAMILY.and,
    fullName: FONT_FAMILY.fullName,

    // for generator purpose
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '32px',
    subHeading: '20px',
    body: '19px',
    body_lv2: '17px',
    name: '30px',
    and: '66px',
    fullName: '30px',
  },
  fontWeights: {
    heading: 400,
    subHeading: 400,
    body: 500,
    body_lv2: 500,
    name: 400,
    and: 300,
    fullName: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
        '--fontItalic-body_lv2': '',
        '--fontItalic-name': '',
        '--fontItalic-and': '',
        '--fontItalic-fullName': '',
      },
    },
  },
  colors: {
    bgPrimary: 'rgba(255,250,250,1)',
    bgSecondary: 'rgba(166,139,82,1)',
    bgAlternative: '#FFFFFF',
    mainAssetColor: 'rgba(166,139,82,1)',
    bgOpacity: '#323030',
    mainColorText: '#2C3F4E',
    secondaryColorText: 'rgba(115,115,115,1)',
    alternativeColorText: '#FEFEFE',
    transparent: 'transparent',
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'subHeading',
      },
    },
  },
};

export default extendTheme(theme);
